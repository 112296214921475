<template>
  <div
    id="recordDetailHistoryViewModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl sx-modal-responsive">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="bi bi-filetype-json me-1"></i>
            <template v-if="viewTypeId == 1">
              {{
                $t(
                  "History.DifferenceBetweenTwoRecords",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
            <template v-else>
              {{
                $t(
                  "History.Information",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-if="data.length > 0">
            <div class="table-responsive">
              <table
                class="table table-hover table-striped table-bordered fs-5"
              >
                <thead class="table-info">
                  <tr>
                    <th>
                      {{
                        $t(
                          "History.FieldName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <template v-if="viewTypeId == 1">
                      <th>
                        {{
                          $t(
                            "History.NewValue",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </th>
                      <th>
                        {{
                          $t(
                            "History.OldValue",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </th>
                    </template>
                    <template v-else>
                      <th>
                        {{
                          $t(
                            "History.Value",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in data" :key="i">
                    <td>
                      {{ item.key }}
                    </td>
                    <td>
                      {{ item.value }}
                    </td>
                    <td v-if="viewTypeId == 1">
                      {{ item.oldValue }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <div class="alert alert-warning mb-0" v-else>
            {{
              $t(
                "History.ItemsNotFound",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger btn-history-modal-close"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mame: "HistoryDetailOrCompareModal",
  props: {
    viewTypeId: {
      type: Number,
      default: 0,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {},
};
</script>
